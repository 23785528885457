<template>
    <default-layout class="homePage">
        <div class="hero">
            <h1>
                Get the scoop on your site.
            </h1>
            <h2>
                SessionScoop records your site's visitors to help you boost
                conversions and user satisfaction.
            </h2>
            <p>
                <b-button
                    type="is-link"
                    size="is-large"
                    tag="router-link"
                    :to="{ name: 'signup' }"
                >
                    Try It For Free
                </b-button>
            </p>
        </div>
        <b-carousel>
            <b-carousel-item>
                <img src="/img/demo/dash.png" />
            </b-carousel-item>
            <b-carousel-item>
                <img src="/img/demo/recs1.png" />
            </b-carousel-item>
            <b-carousel-item>
                <img src="/img/demo/recs2.png" />
            </b-carousel-item>
            <b-carousel-item>
                <img src="/img/demo/recs3.png" />
            </b-carousel-item>
            <b-carousel-item>
                <img src="/img/demo/rec1.png" />
            </b-carousel-item>
            <b-carousel-item>
                <img src="/img/demo/rec2.png" />
            </b-carousel-item>
            <b-carousel-item>
                <img src="/img/demo/acc1.png" />
            </b-carousel-item>
            <b-carousel-item>
                <img src="/img/demo/acc2.png" />
            </b-carousel-item>
            <b-carousel-item>
                <img src="/img/demo/acc3.png" />
            </b-carousel-item>
            <b-carousel-item>
                <img src="/img/demo/teams.png" />
            </b-carousel-item>
        </b-carousel>
        <div class="features">
            <header>
                <h2>Packed with awesome features!</h2>
            </header>
            <div class="columns is-multiline">
                <div class="column is-one-third">
                    <article class="media">
                        <figure class="media-left">
                            <b-icon icon="play"></b-icon>
                        </figure>
                        <div class="media-content">
                            <h3>Recordings</h3>
                            <ul>
                                <li>
                                    <i class="fas fa-check"></i>
                                    <span
                                        >See how users interact with your
                                        site</span
                                    >
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    <span
                                        >Identify glitches and drop-off
                                        points</span
                                    >
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    <span
                                        >Quickly find recordings by URL, page
                                        title, location and more</span
                                    >
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    <span>Tag your users and sessions</span>
                                </li>
                            </ul>
                        </div>
                    </article>
                </div>
                <div class="column is-one-third">
                    <article class="media">
                        <figure class="media-left">
                            <b-icon icon="chart-line"></b-icon>
                        </figure>
                        <div class="media-content">
                            <h3>Analytics</h3>
                            <ul>
                                <li>
                                    <i class="fas fa-check"></i>
                                    <span>Browser breakdowns</span>
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    <span>JavaScript errors</span>
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    <span>Top landing and exit pages</span>
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    <span>Visitor locations and more...</span>
                                </li>
                            </ul>
                        </div>
                    </article>
                </div>
                <div class="column is-one-third">
                    <article class="media">
                        <figure class="media-left">
                            <b-icon icon="shield-alt"></b-icon>
                        </figure>
                        <div class="media-content">
                            <h3>Privacy Controls</h3>
                            <ul>
                                <li>
                                    <i class="fas fa-check"></i>
                                    <span
                                        >Exclude HTML content from
                                        recordings</span
                                    >
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    <span>Exclude input fields</span>
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    <span>Mask all input fields</span>
                                </li>
                            </ul>
                        </div>
                    </article>
                </div>
                <div class="column is-one-third">
                    <article class="media">
                        <figure class="media-left">
                            <b-icon icon="code"></b-icon>
                        </figure>
                        <div class="media-content">
                            <h3>Easy Setup</h3>
                            <ul>
                                <li>
                                    <i class="fas fa-check"></i>
                                    <span>
                                        Add a simple script to your site and
                                        you're ready to rock!
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </article>
                </div>
                <div class="column is-one-third">
                    <article class="media">
                        <figure class="media-left">
                            <b-icon icon="user-friends"></b-icon>
                        </figure>
                        <div class="media-content">
                            <h3>Team Members</h3>
                            <ul>
                                <li>
                                    <i class="fas fa-check"></i>
                                    <span
                                        >Invite all your team members to
                                        collaborate</span
                                    >
                                </li>
                            </ul>
                        </div>
                    </article>
                </div>
                <div class="column is-one-third">
                    <article class="media">
                        <figure class="media-left">
                            <b-icon icon="globe"></b-icon>
                        </figure>
                        <div class="media-content">
                            <h3>Unlimited Sites</h3>
                            <ul>
                                <li>
                                    <i class="fas fa-check"></i>
                                    <span>Get the scoop on all your sites</span>
                                </li>
                            </ul>
                        </div>
                    </article>
                </div>
            </div>
            <footer>
                <b-button
                    type="is-link"
                    size="is-large"
                    tag="router-link"
                    :to="{ name: 'signup' }"
                >
                    Try it For Free
                </b-button>
            </footer>
        </div>
    </default-layout>
</template>

<script>
export default {
    name: 'Home',
}
</script>
